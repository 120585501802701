<template>
    <button class="btn btn-gray btn-sm mr-1 ml-1" @click="exportFile">
        <i class="fa fa-download icon-left"></i> Xuất dữ liệu
        <b-modal v-model="exporting" title="Đang tải dữ liệu" @close="close" no-close-on-esc no-close-on-backdrop no-enforce-focus>
            <div class="alert alert-warning">
                <b-spinner small variant="primary"></b-spinner>&nbsp;
                <span>Dữ liệu đang được tải xuống! Xin vui lòng đợi trong giây lát và xin đừng tắt cửa sổ này cho đến khi quá trình tải xuống hoàn tất.</span>
                {{downloadedCount}} - {{totalCount}}
                <b-progress :max="totalCount">
                    <b-progress-bar :value="downloadedCount" :label="`${((downloadedCount / totalCount) * 100).toFixed(0)}%`"></b-progress-bar>
                </b-progress>
            </div>
        </b-modal>
    </button>
</template>

<script>
    import Export from "@/mixins/Export";
    import moment from 'moment';
    export default {
        props: ['filter', 'totalCount', 'params', 'prime'],
        mixins: [Export],
        data() {
            var headers = [
                {header: 'code', key: 'code',label: 'Mã người cung cấp dịch vụ', width: 30},
                {header: 'type', key: 'type', label: 'Loại hình dịch vụ cung cấp', width: 30},
                {header: 'fullname', key: 'fullname', label: 'Họ và tên', width: 30},
                {header: 'year', key: 'year', label: 'Năm sinh', width: 10},
                {header: 'gender_id', key: 'gender_id', label: 'Giới tính', width: 10},
                {header: 'endTime', key: 'endTime', label: 'Ngày hoàn thành đào tạo', width: 15},
                {header: 'site_name', key: 'site_name', label: 'Đơn vị công tác', width: 25},
                {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố', width: 25},
            ];
            if (this.prime) {
                headers.unshift({header: 'client_id', key: 'client_id', label: 'Đơn vị', width: 20});
            }
            return {
                service: '/edu-patient',
                fileName: `edu-patient`,
                headers,
            }
        },
        methods: {
            setupSheet: function (sheet) {
                let cols = [];
                let row = {};
                for (let i in this.headers) {
                    let header = this.headers[i];
                    cols.push(header);
                    row[header.key] = header.label;
                }
                sheet.columns = cols;
                sheet.addRow(row);

                let provinces = [];
                for (const [value] of Object.entries(this.params.provinceExport)) {
                    provinces.push(this.params.provinceExport[value]);
                }

                row = {};
                if(this.prime){
                    row['client_id'] = "Nhập định dạng chữ";
                }
                row["code"] = "Nhập định dạng chữ (CCCD/CMT)";
                row["type"] = "Nhập 01 giá trị từ 1 đến 4 (Trong đó: " + this.$params.eduPatient.providerTypeOptions.filter(item => item.value != null).map(item => item.text).toString() + ")";
                row["fullname"] = "Nhập định dạng chữ (tối đa 200 ký tự)";
                row["year"] = "Nhập định dạng số (yyyy - Giá trị từ 1911 đến năm nhập dữ liệu)";
                row["province_id"] = "Nhập định dạng chữ (" + provinces.toString() + ")";
                row["gender_id"] = "Nhập 01 giá trị dạng chữ (Nam/Nữ/Khác)";
                row["endTime"] = "Nhập định dạng ngày/tháng/năm (dd/mm/yyyy)";
                row["site_name"] = "Nhập định dạng chữ";
                sheet.addRow(row);
                row.alignment = {wrapText: true};
                //ẩn dòng 1
                sheet.getRow(1).hidden = true;
                sheet.getRow(1).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'bdbdbd'
                    }
                };

                // đôi đậm và wrap text
                sheet.getRow(2).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(2).font = {
                    bold: true
                };
                //bôi màu
                sheet.getRow(2).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '92D050'
                    }
                };
                //Dòng 3
                sheet.getRow(3).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(3).font = {
                    italic: true
                };
            },
            addRow(item, params) {
                let row = {
                    fullname: item.fullname,
                    code: item.code,
                    year: item.year,
                    gender_id : this.$params.gender.find(o => o.value == item.gender_id).text,
                    endTime: params.endTime[item.id] > 0 ? moment(params.endTime[item.id] * 1000).format('DD/MM/YYYY') : '',
                    province_id: params.provinces && params.provinces[item.province_id] ? params.provinces[item.province_id].name : '',
                    type: this.getTypes(params.types[item.id] ? params.types[item.id] : null),
                    site_name : item.site_name,
                };
                if(this.prime){
                    row['client_id'] = params.clients[item.client_id].short_name;
                }
                return row;
            },
            getTypes(type) {
                let text = "";
                if (type != null) {
                    for (let item of type) {
                        if (this.$params.eduPatient.providerTypeOptions.find(o => o.value == item)) {
                            text = text + this.$params.eduPatient.providerTypeOptions.find(o => o.value == item).text + "; ";
                        }
                    }
                }
                return text.substring(0, text.length - 2);
            },
            // getTypes(ID) {
            //     let text = "";
            //     console.log(this.params.types[ID]);
            //     if (this.params.types[ID]) {
            //         for (let item of this.params.types[ID]) {
            //             if(this.$params.eduPatient.providerTypeOptions.find(o => o.value == item)){
            //                 text = text + this.$params.eduPatient.providerTypeOptions.find(o => o.value == item).text + "; ";
            //             }
            //         }
            //     }
            //     return text.substring(0, text.length - 2);
            // },
        }
    }
</script>