<template>
    <div class="row">
        <div class="col-12" >
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :placeholder="'Nhập mã, họ tên ...'" :items="items" :fields="fields" @load="load" @reset="reset"
                     title="Danh sách cán bộ" titleIcon="fa fa-user">
                <template slot="filter">
                    <client-select v-if='isPrime() || isTW() ||  isUsaid()' attribute="client_id"  :model="filterFormModel" :errors="formErrors"  :filter="{parent_id: $store.state.client.id}" :is-filter='true' :placeHolder="isPrime() ? 'Chọn đơn vị thầu phụ' : 'Chọn đơn vị thầu chính'" class="mr-2" @change='searchClient' />
                    <client-select v-if='(isTW() ||  isUsaid()) && filterFormModel.client_id' attribute="client_sub_id"  :model="filterFormModel" :errors="formErrors" placeHolder="Chọn đơn vị thầu phụ"  :filter="{parent_id: filterFormModel.client_id}" :is-filter='true' class="mr-2" @change='search' />
                    <province-select :model="filterFormModel" attribute="province_id" :is-filter='true' class="mr-2" @change='search' />
                    <!-- <time-range-picker class="mr-1" :model="filterFormModel" label="Ngày hoàn thành đào tạo" fromAttribute="fromTime" toAttribute="toTime" :defaultShortcut="10" @change="search" /> -->
                    <dropdown type="bordered" class="mr-2" :model="filterFormModel" attribute="type" :options="$params.eduPatient.providerTypeOptions" :isFilter="true" @change="search"/>
                </template>
                <template #cell(client_id)="{item}">
                    <span v-if='params.clients[item.client_id]' >{{params.clients[item.client_id].short_name}}</span>
                </template>
                <template #cell(info)="{item}">
                    <div v-if="item.fullname"><span class="small text-gray-999">Mã: </span>{{ item.code }}</div>
                    <div v-if="item.fullname"><span class="small text-gray-999">Họ và tên: </span>{{item.fullname }}</div>
                    
                </template>
                <template #cell(in)="{item}">
                <div v-if="item.year"><span class="small text-gray-999">Năm sinh: </span>{{ item.year }}</div>
                    <div><span class="small text-gray-999">Giới tính: </span>{{item.gender_id == 0 ? 'Nam' : item.gender_id == 1 ? 'Nữ' : 'Khác'}}</div>
                    <span v-if="params.types[item.id]">
                    <span class="small text-gray-999">Loại hình người CCDV: </span>
                        <span v-for="(type) in params.types[item.id]" :key="type">
                            <span v-if="$params.eduPatient.providerTypeOptions[type]">
                                {{$params.eduPatient.providerTypeOptions[type].text}}
                            </span>
                        </span>
                    </span>
                </template>
                <template #cell(time)="{item}">
                    <span v-if="params.endTime[item.id]">{{params.endTime[item.id] * 1000 |  moment('DD/MM/YYYY')}} </span>
                </template>
                <template #cell(province)="{item}">
                    <div v-if="item.province_id">    
                        <span v-if='params.provinces && params.provinces[item.province_id]'>{{ params.provinces[item.province_id].name }}</span>
                        <span v-else>[{{item.province_id}}] Không xác định</span>
                    </div>
                </template>
                <template slot="action">
                    <export :filter="filterFormModel" :totalCount="itemCount" :params='params' :prime="isPrime() || isTW() || isUsaid()" class="mr-1"/>
                    <g-button class="ml-2" v-if="permissions('patient_create') && isSub()" :loading="saveLoading" size="sm" variant="success" icon="plus" content="Thêm mới" @click='create'/>
                </template>
                <template #cell(action)="{item}">
                    <g-button v-if="permissions('edu-patient_view')" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="fa fa-eye" @click="view(item.id)" />
                    <g-button v-if="permissions('edu-patient_update') && isSub()" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="pencil"  @click="update(item.id)"/>
                    <g-button  v-if="permissions('edu-patient_del') && isSub()" :loading="delLoading" size="sm" variant="outline-danger" class="ml-1 mb-1" icon="trash-fill" @click='del(item.id)'/>
                    <log type="edu-patient" :targetId="item.id" title="Lịch sử" :isStaff="true" class="ml-1 mb-1" ></log>
                </template>
            </g-table>
        </div>
        <b-modal v-model="showForm" size='lg' :title="formTitle" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-md-6'>
                    <form-select label="Loại hình người CCDV" :required='true' :model="formModel" attribute="types" :errors="formErrors" :options="providerTypeOptions"/>
                </div>
            </div>  
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Mã người cung cấp dịch vụ" :disabled='formModel.id' :required='true' :model="formModel" attribute="code" :errors="formErrors"></form-input>
                </div>
                <div class='col-md'>
                    <form-input label="Họ và tên" :required='true' :model="formModel" attribute="fullname" :errors="formErrors"></form-input>
                </div>
            </div>
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Năm sinh" type="number" :required='true' :model="formModel" attribute="year"  :errors="formErrors" placeholder="YYYY"></form-input>
                </div>
                <div class='col-md'>
                    <form-select label="Giới tính" :required='true' :model="formModel" attribute="gender_id" :errors="formErrors" :options="$params.gender"/>
                </div>  
            </div> 
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Đơn vị công tác" :required='false' :model="formModel" attribute="site_name"  :errors="formErrors"></form-input>
                </div>
                <div class='col-md'>
                    <province-select label="Tỉnh/Thành phố" :required='true' :model="formModel" attribute="province_id" :errors="formErrors"/>
                </div>
            </div>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <em v-else class="fa fa-save"></em>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
        <b-modal v-model="showFormView" size='lg' :title="formTitle" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-md-6'>
                    <form-select :required='true' :disabled='true' label="Loại hình người CCDV" :model="formModel" attribute="types" :errors="formErrors" :options="providerTypeOptions"/>
                </div>
            </div> 
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Mã người cung cấp dịch vụ" :disabled='true' :required='true' :model="formModel" attribute="code" :errors="formErrors"></form-input>
                </div>
                <div class='col-md'>
                    <form-input label="Họ và tên" :disabled='true' :required='true' :model="formModel" attribute="fullname" :errors="formErrors"></form-input>
                </div>
            </div>
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Năm sinh" :required='true' :disabled='true' type="number" :model="formModel" attribute="year"  :errors="formErrors" placeholder="YYYY"></form-input>
                </div>
                <div class='col-md'>
                    <form-select label="Giới tính" :required='true' :disabled='true' :model="formModel" attribute="gender_id" :errors="formErrors" :options="$params.gender"/>
                </div>  
            </div> 
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Đơn vị công tác" :disabled='true' :required='false' :model="formModel" attribute="site_name"  :errors="formErrors"></form-input>
                </div>
                <div class='col-md'>
                    <province-select label="Tỉnh/Thành phố" :required='true' :disabled='true' :model="formModel" attribute="province_id" :errors="formErrors"/>
                </div>
            </div>
            <template v-slot:modal-footer="{ cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import role from '@/mixins/Role';
    import ClientSelect from '@/components/ClientSelect';
    import ProvinceSelect from '@/components/ProvinceSelect';
    import Log from "@/components/Log";
    import Export from './EduPatientExport';
    import Dropdown from '@/components/Dropdown';

    export default {
        components: {ClientSelect, ProvinceSelect, Log, Export, Dropdown},
        mixins: [list, crud, role],
        data: function () {
            return {
                service: '/edu-patient',
                defaultFilterFormModel: {
                    parameters: [
                        this.$params.const.race.key,
                    ],
                    keyword: '',
                    client_id: this.isPrime() || this.isTW() || this.isUsaid() ? '' : this.$store.state.client.id,
                    types: [],
                    type: null,
                    client_sub_id: null,
                },
                defaultFormModel: {
                    type: null,
                    code: null,
                    gender_id: null,
                    types: null,
                },
                providerTypeOptions: [],
                showFormView: false,
            };
        },
        computed: {
            fields() {
                let fields = [
                    {key: 'info', label: "Người cung cấp dịch vụ"},
                    {key: 'in', label: "Thông tin khác"},
                    {key: 'province', label: "Tỉnh/Thành phố"},
                    {key: 'time', label: "Ngày hoàn thành đào tạo", class: 'text-center'},
                    {key: 'action', label: "Hành động", class: 'text-right'},
                ];
                if (this.isPrime() || this.isTW() || this.isUsaid()) {
                    return [{key: 'client_id', label: "Đơn vị"}].concat(fields);
                }
                return fields;
            },
        },
        watch: {
            "formModel.code": {
                handler(val) {
                    if (val) {
                        this.formModel.code = val.toUpperCase().trim();
                    }
                }, deep: true
            }
        },
        methods: {
            searchClient() {
                this.filterFormModel.client_sub_id = null;
                this.filterFormModel.page = 1;
                this.load();
            },
            view: function (id) {
                let uri = this.service;
                if (id) {
                    uri += '/get?id=' + id;
                }
                this.updateLoading = true;
                this.$service.get(uri).then(response => {
                    this.formModel = {};
                    this.formErrors = {};
                    this.showFormView = true;
                    this.formModel = response.data;
                    this.formTitle = "Xem thông tin Người CCDV: " + this.formModel.fullname;
                    this.formModel.id = id;
                }).finally(() => {
                    this.updateLoading = false;
                });
            },
            getEduPatientOption(){
                for (let i in this.$params.eduPatient.providerTypeOptions){
                    let id = this.$params.eduPatient.providerTypeOptions[i];
                    this.providerTypeOptions.push(id);
                }
                return this.providerTypeOptions;
            },
        },
        mounted(){
            this.getEduPatientOption();
        },
    }
</script>